export const currencies = [
	{
		code: "USD",
		symbol: "$",
		name: "United States Dollar",
		logo: "https://upload.wikimedia.org/wikipedia/commons/a/a9/Flag_of_the_United_States_%28DoS_ECA_Color_Standard%29.svg",
	},
	{ code: "ETH", symbol: "Ξ", name: "Ethereum", logo: "https://upload.wikimedia.org/wikipedia/commons/7/70/Ethereum_logo.svg" },
	{ code: "BTC", symbol: "฿", name: "Bitcoin", logo: "https://upload.wikimedia.org/wikipedia/commons/4/46/Bitcoin.svg" },
	{ code: "NGN", symbol: "₦", name: "Nigerian Naira", logo: "https://upload.wikimedia.org/wikipedia/commons/7/79/Flag_of_Nigeria.svg" },
	{ code: "USDT", symbol: "₮", name: "Tether", logo: "/logos/tether.svg" },
	{ code: "USDC", symbol: "USDC", name: "USD Coin", logo: "/logos/usdc.svg" },
	{ code: "CUSD", symbol: "CUSD", name: "Celo Dollar", logo: "/logos/cusd.svg" },
	{ code: "TRX", symbol: "TRX", name: "TRON", logo: "/logos/trx.svg" },
	{ code: "CELO", symbol: "CELO", name: "Celo" },
	{ code: "UGX", symbol: "ush", name: "Ugandan Shilling", logo: "https://upload.wikimedia.org/wikipedia/commons/4/4e/Flag_of_Uganda.svg" },
	{ code: "KES", symbol: "KSh", name: "Kenyan Shilling", logo: "https://upload.wikimedia.org/wikipedia/commons/4/49/Flag_of_Kenya.svg" },
	{ code: "ALL", symbol: "Lek", name: "Albanian Lek" },
	{ code: "AFN", symbol: "؋", name: "Afghan Afghani" },
	{ code: "ARS", symbol: "$", name: "Argentine Peso" },
	{ code: "AWG", symbol: "ƒ", name: "Aruban Florin" },
	{ code: "AUD", symbol: "$", name: "Australian Dollar" },
	{ code: "AZN", symbol: "₼", name: "Azerbaijani Manat" },
	{ code: "BSD", symbol: "$", name: "Bahamian Dollar" },
	{ code: "BYN", symbol: "Br", name: "Belarusian Ruble" },
	{ code: "BZD", symbol: "BZ$", name: "Belize Dollar" },
	{ code: "BMD", symbol: "$", name: "Bermudian Dollar" },
	{ code: "BOB", symbol: "$b", name: "Bolivian Boliviano" },
	{ code: "BAM", symbol: "KM", name: "Bosnia and Herzegovina Convertible Mark" },
	{ code: "BWP", symbol: "P", name: "Botswana Pula" },
	{ code: "BGN", symbol: "лв", name: "Bulgarian Lev" },
	{ code: "BRL", symbol: "R$", name: "Brazilian Real" },
	{ code: "BND", symbol: "$", name: "Brunei Dollar" },
	{ code: "KHR", symbol: "៛", name: "Cambodian Riel" },
	{ code: "CAD", symbol: "$", name: "Canadian Dollar" },
	{ code: "KYD", symbol: "$", name: "Cayman Islands Dollar" },
	{ code: "CLP", symbol: "$", name: "Chilean Peso" },
	{ code: "CNY", symbol: "¥", name: "Chinese Yuan" },
	{ code: "COP", symbol: "$", name: "Colombian Peso" },
	{ code: "CRC", symbol: "₡", name: "Costa Rican Colón" },
	{ code: "HRK", symbol: "kn", name: "Croatian Kuna" },
	{ code: "CUP", symbol: "₱", name: "Cuban Peso" },
	{ code: "CZK", symbol: "Kč", name: "Czech Koruna" },
	{ code: "DKK", symbol: "kr", name: "Danish Krone" },
	{ code: "DOP", symbol: "RD$", name: "Dominican Peso" },
	{ code: "XCD", symbol: "$", name: "East Caribbean Dollar" },
	{ code: "EGP", symbol: "£", name: "Egyptian Pound" },
	{ code: "SVC", symbol: "$", name: "Salvadoran Colón" },
	{ code: "EUR", symbol: "€", name: "Euro" },
	{ code: "FKP", symbol: "£", name: "Falkland Islands Pound" },
	{ code: "FJD", symbol: "$", name: "Fijian Dollar" },
	{ code: "GHS", symbol: "¢", name: "Ghanaian Cedi" },
	{ code: "GIP", symbol: "£", name: "Gibraltar Pound" },
	{ code: "GTQ", symbol: "Q", name: "Guatemalan Quetzal" },
	{ code: "GGP", symbol: "£", name: "Guernsey Pound" },
	{ code: "GYD", symbol: "$", name: "Guyanese Dollar" },
	{ code: "HNL", symbol: "L", name: "Honduran Lempira" },
	{ code: "HKD", symbol: "$", name: "Hong Kong Dollar" },
	{ code: "HUF", symbol: "Ft", name: "Hungarian Forint" },
	{ code: "ISK", symbol: "kr", name: "Icelandic Króna" },
	{ code: "INR", symbol: "₹", name: "Indian Rupee" },
	{ code: "IDR", symbol: "Rp", name: "Indonesian Rupiah" },
	{ code: "IRR", symbol: "﷼", name: "Iranian Rial" },
	{ code: "IMP", symbol: "£", name: "Isle of Man Pound" },
	{ code: "ILS", symbol: "₪", name: "Israeli New Shekel" },
	{ code: "JMD", symbol: "J$", name: "Jamaican Dollar" },
	{ code: "JPY", symbol: "¥", name: "Japanese Yen" },
	{ code: "JEP", symbol: "£", name: "Jersey Pound" },
	{ code: "KZT", symbol: "лв", name: "Kazakhstani Tenge" },
	{ code: "KPW", symbol: "₩", name: "North Korean Won" },
	{ code: "KRW", symbol: "₩", name: "South Korean Won" },
	{ code: "KGS", symbol: "лв", name: "Kyrgyzstani Som" },
	{ code: "LAK", symbol: "₭", name: "Lao Kip" },
	{ code: "LBP", symbol: "£", name: "Lebanese Pound" },
	{ code: "LRD", symbol: "$", name: "Liberian Dollar" },
	{ code: "MKD", symbol: "ден", name: "Macedonian Denar" },
	{ code: "MYR", symbol: "RM", name: "Malaysian Ringgit" },
	{ code: "MUR", symbol: "₨", name: "Mauritian Rupee" },
	{ code: "MXN", symbol: "$", name: "Mexican Peso" },
	{ code: "MNT", symbol: "₮", name: "Mongolian Tögrög" },
	{ code: "MZN", symbol: "MT", name: "Mozambican Metical" },
	{ code: "NAD", symbol: "$", name: "Namibian Dollar" },
	{ code: "NPR", symbol: "₨", name: "Nepalese Rupee" },
	{ code: "ANG", symbol: "ƒ", name: "Netherlands Antillean Guilder" },
	{ code: "NZD", symbol: "$", name: "New Zealand Dollar" },
	{ code: "NIO", symbol: "C$", name: "Nicaraguan Córdoba" },
	{ code: "NOK", symbol: "kr", name: "Norwegian Krone" },
	{ code: "OMR", symbol: "﷼", name: "Omani Rial" },
	{ code: "PKR", symbol: "₨", name: "Pakistani Rupee" },
	{ code: "PAB", symbol: "B/.", name: "Panamanian Balboa" },
	{ code: "PYG", symbol: "Gs", name: "Paraguayan Guarani" },
	{ code: "PEN", symbol: "S/.", name: "Peruvian Sol" },
	{ code: "PHP", symbol: "₱", name: "Philippine Peso" },
	{ code: "PLN", symbol: "zł", name: "Polish Zloty" },
	{ code: "QAR", symbol: "﷼", name: "Qatari Rial" },
	{ code: "RON", symbol: "lei", name: "Romanian Leu" },
	{ code: "RUB", symbol: "₽", name: "Russian Ruble" },
	{ code: "SHP", symbol: "£", name: "Saint Helena Pound" },
	{ code: "SAR", symbol: "﷼", name: "Saudi Riyal" },
	{ code: "RSD", symbol: "Дин.", name: "Serbian Dinar" },
	{ code: "SCR", symbol: "₨", name: "Seychellois Rupee" },
	{ code: "SGD", symbol: "$", name: "Singapore Dollar" },
	{ code: "SBD", symbol: "$", name: "Solomon Islands Dollar" },
	{ code: "SOS", symbol: "S", name: "Somali Shilling" },
	{ code: "ZAR", symbol: "R", name: "South African Rand" },
	{ code: "LKR", symbol: "₨", name: "Sri Lankan Rupee" },
	{ code: "SEK", symbol: "kr", name: "Swedish Krona" },
	{ code: "CHF", symbol: "CHF", name: "Swiss Franc" },
	{ code: "SRD", symbol: "$", name: "Surinamese Dollar" },
	{ code: "TWD", symbol: "NT$", name: "New Taiwan Dollar" },
	{ code: "THB", symbol: "฿", name: "Thai Baht" },
	{ code: "TZS", symbol: "TSh", name: "Tanzanian Shilling", logo: "https://upload.wikimedia.org/wikipedia/commons/3/38/Flag_of_Tanzania.svg" },
	{ code: "TOP", symbol: "T$", name: "Tongan Paʻanga" },
	{ code: "TTD", symbol: "$", name: "Trinidad and Tobago Dollar" },
	{ code: "TND", symbol: "د.ت", name: "Tunisian Dinar" },
	{ code: "TRY", symbol: "₺", name: "Turkish Lira" },
	{ code: "UAH", symbol: "₴", name: "Ukrainian Hryvnia" },
	{ code: "AED", symbol: "د.إ", name: "United Arab Emirates Dirham" },
	{ code: "GBP", symbol: "£", name: "British Pound Sterling" },
	{ code: "VND", symbol: "₫", name: "Vietnamese Dong" },
	{ code: "XOF", symbol: "CFA", name: "West African CFA Franc" },
	{ code: "XAF", symbol: "CFA", name: "Central African CFA Franc" },
	{ code: "ZMW", symbol: "ZK", name: "Zambian Kwacha" },
	{ code: "ZWL", symbol: "Z$ ", name: "Zimbabwean Dollar" },
];
