import { useQuery } from "@tanstack/react-query";
import { createContext, useContext, useEffect, useReducer, useState } from "react";
import { useAuth } from "../AuthProvider";
import api from "../../api";
import Big from "big.js";
import { currencies } from "./currencies";
import { useFeatureFlags } from "../FeatureFlags/FeatureFlagsProvider";
import _ from "lodash";

const RatesContext = createContext();
const RatesDispatchContext = createContext();

export const CURRENCY_TYPES = {
	FIAT: "FIAT",
	CRYPTO: "CRYPTO",
};

export const RATES_ACTIONS = {
	updateState: "updateState",
	updateStates: "updateStates",
	fetchedPairPrices: "fetchedPairPrices",
};

export const RatesProvider = ({ children }) => {
	const { isFeatureFlagEnabled } = useFeatureFlags();
	const { isLoggedIn } = useAuth();

	const getRates = useQuery({
		queryKey: ["rates"],
		queryFn: () => {
			ratesDispatch({
				type: "fetchingRates",
			});
			return api.getRates();
		},
		enabled: false,
	});

	const getPairPrices = useQuery({
		queryKey: ["pairprices"],
		queryFn: () => {
			ratesDispatch({ type: RATES_ACTIONS.updateState, key: "isLoadingPairPrices", value: true });
			return api.getPairPrices();
		},
		enabled: false,
	});

	const fiatCurrencies = ["KES", "USD", "UGX", "NGN", "TZS"];
	const cryptoCurrencies = ["BTC", "ETH", "USDT", "USDC", "CUSD", "TRX"];

	const supportedCurrencies = [];
	supportedCurrencies.push(...fiatCurrencies);

	if (isFeatureFlagEnabled("cryptocurrencies")) {
		supportedCurrencies.push(...cryptoCurrencies);
	}

	const filteredCurrencies = currencies.filter((currency) => supportedCurrencies.includes(currency.code));

	const getRate = ({ rates, from, to }) => {
		let rate = "";

		// If `from` currency == rates.base, return the basic exchange rate for the `to` currency
		if (from == rates.base) {
			rate = Big(rates.rates[to]).toString();
			return rate;
		}

		// If `to` currency === fx.base, return the basic inverse rate of the `from` currency
		if (to === rates.base) {
			rate = Big(1).div(Big(rates.rates[from])).toString();
			return rate;
		}

		// Otherwise, return the `to` rate multipled by the inverse of the `from` rate to get the
		// relative exchange rate between the two currencies
		rate = Big(rates.rates[to])
			.mul(Big(1).div(Big(rates.rates[from])))
			.toString();

		return rate;
	};

	const convert = ({ rates, from, to, amount }) => {
		const rate = getRate({ rates, from, to });
		const newAmount = Big(amount).mul(Big(rate)).toString();
		return newAmount;
	};

	const isFiat = new Set();

	const isCrypto = new Set();

	const [ratesState, ratesDispatch] = useReducer(ratesReducer, {
		rates: {},
		isLoadingRates: false,
		getRate,
		convert,
		currencies: filteredCurrencies,
		isFiat,
		isCrypto,
		isLoadingPairPrices: false,
		pairprices:[]
	});

	//get rates
	useEffect(() => {
		(async () => {
			const ratesRefetchResult = await getRates.refetch();
			if (ratesRefetchResult.status == "success") {
				const newRates = ratesRefetchResult.data.data.data.attributes;
				ratesDispatch({
					type: "fetchedRates",
					rates: newRates,
				});
			}

			const pairPricesRefetchResult = await getPairPrices.refetch();
			if (pairPricesRefetchResult.status == "success") {
				const newPairPrices = pairPricesRefetchResult.data.data.data.attributes;
				ratesDispatch({
					type: RATES_ACTIONS.fetchedPairPrices,
					pairprices: newPairPrices,
				});
			}
		})();
	}, [isLoggedIn]);

	return (
		<RatesContext.Provider value={ratesState}>
			<RatesDispatchContext.Provider value={ratesDispatch}>{children}</RatesDispatchContext.Provider>
		</RatesContext.Provider>
	);
};

export const useRates = () => {
	return useContext(RatesContext);
};

export const useRatesDispatch = () => {
	return useContext(RatesDispatchContext);
};

const ratesReducer = (ratesState, action) => {
	switch (action.type) {
		case "fetchedRates":
			// Convert rates to appropriate format
			const rates = {
				base: "USD",
				rates: {},
			};

			const isFiat = new Set();

			const isCrypto = new Set();

			Object.keys(action.rates.rates.cryptocurrency).forEach((crypto) => {
				isCrypto.add(crypto);
				rates.rates[crypto] = action.rates.rates.cryptocurrency[crypto].rate;
			});

			Object.keys(action.rates.rates.fiat).forEach((fiat) => {
				isFiat.add(fiat);
				rates.rates[fiat] = action.rates.rates.fiat[fiat].rate;
			});

			const getCurrencyType = (code) => {
				let currencyType;

				if (isFiat.has(code)) {
					currencyType = CURRENCY_TYPES.FIAT;
				}

				if (isCrypto.has(code)) {
					currencyType = CURRENCY_TYPES.CRYPTO;
				}

				return currencyType;
			};

			return { ...ratesState, rates, isLoadingRates: false, isFiat, isCrypto, getCurrencyType };
		case "fetchingRates":
			return { ...ratesState, isLoadingRates: true };
		case RATES_ACTIONS.fetchedPairPrices: {
			const newRatesState = { ...ratesState };
			let pairprices = [];

			Object.keys(action.pairprices.pairprices).forEach((pair) => {
				//Determine from and to depending on rate value being > 1;
				const { getRate, rates, currencies } = ratesState;
				const codeTokens = pair.split("/");

				const rate1 = getRate({
					rates,
					from: codeTokens[0],
					to: codeTokens[1],
				});

				const rate2 = getRate({
					rates,
					from: codeTokens[1],
					to: codeTokens[0],
				});

				const pairprice = action.pairprices.pairprices[pair];
				const from = {};
				const to = {};
				let referencePrice;
				if (rate1 > 1) {
					from.code = codeTokens[0];
					to.code = codeTokens[1];
					referencePrice = rate1;
				} else {
					from.code = codeTokens[1];
					to.code = codeTokens[0];
					referencePrice = rate2;
				}
				from.logo = _.find(currencies, { code: from.code })?.logo;
				to.logo = _.find(currencies, { code: to.code })?.logo;

				const buyPrice = Big(referencePrice)
					.sub(Big(referencePrice).mul(Big(pairprice.markdown).div(Big(100))))
					.toString();
				const sellPrice = Big(referencePrice)
					.add(Big(referencePrice).mul(Big(pairprice.markup).div(Big(100))))
					.toString();

				pairprice.from = from;
				pairprice.to = to;
				pairprice.referencePrice = referencePrice;
				pairprice.buyPrice = buyPrice;
				pairprice.sellPrice = sellPrice;
				pairprice.pair = pair;

				pairprices.push(pairprice);
			});

			return { ...newRatesState, pairprices, isLoadingPairPrices: false };
		}
		case RATES_ACTIONS.updateState: {
			const newRatesState = { ...ratesState };
			newRatesState[action.key] = action.value;
			return newRatesState;
		}
		case RATES_ACTIONS.updateStates: {
			const newRatesState = { ...ratesState, ...action.states };
			return newRatesState;
		}
		default: {
			throw Error("[ratesReducer] Unknown action: " + action.type);
		}
	}
};
